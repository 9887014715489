import React, { useState, useEffect } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { ToastContainer } from 'react-toastify';

import PATHS from 'routes';
import { runMiddlewares, getStore, getState } from './services/ReduxService';
import { isLoggedIn } from './selectors/auth';
import { Helmet } from 'react-helmet';
import SpecialToken from 'components/Login/SpecialToken';

import {
	Home,
	Event,
	Information,
	Watch,
	Navigation,
	GetOTP,
	VerifyOTP,
	TokenLogin,
	Footer,
	Registration,
	ResetPassword,
	ATLogin,
	Agenda,
	GoogleTag,
	EventHome,
	SystemRequirement,
	EditSession,
	Speaker,
	Judges,
	Finalists,
} from 'components/AllComponents';

const DEFAULT_TITLE = 'ANZIIF';

const PublicRoute = (props) => {
	const { title, path, component } = props;
	document.title = title ? title : DEFAULT_TITLE;
	window.scroll(0, 0);
	return <Route path={path} component={component} />;
};

const PrivateRoute = (props) => {
	const { title, path, component } = props;
	let { slug } = props.computedMatch.params;
	if (!slug)
		return <Redirect to={{ pathname: PATHS.HOME, state: { from: path } }} />;
	const state = getState();
	const loggedIn = isLoggedIn(state, slug);

	if (loggedIn) {
		return <PublicRoute title={title} path={path} component={component} />;
	} else {
		if (slug) return <Redirect to={`/`} />;
		else return <Redirect to="/" />;
	}
};

function App() {
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		runMiddlewares(() => {
			setIsLoading(false);
		});
	}, []);

	if (isLoading) return <div className="App">Loading...</div>;

	const reduxStore = getStore();
	return (
		<Provider store={reduxStore}>
			<Helmet>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://cdn.myth.theoplayer.com/470cfd0a-b6ef-46b1-8801-6f5018771488/ui.css"
				/>
			</Helmet>

			<Router basename={process.env.PUBLIC_URL}>
				<div className="nav-wrapper-container">
					<Navigation />
					<Container className="wrapper-container">
						<GoogleTag />
						<div className="wrapper">
							<Switch>
								<PublicRoute exact path={PATHS.HOME} component={Home} />
								<PublicRoute exact path={PATHS.EVENT} component={Event} />
								<PublicRoute exact path={PATHS.SPEAKERS} component={Speaker} />
								<PublicRoute exact path={PATHS.JUDGES} component={Judges} />
								<PublicRoute
									exact
									path={PATHS.FINALISTS}
									component={Finalists}
								/>
								<PublicRoute
									exact
									path={PATHS.EVENT_HOME}
									component={EventHome}
								/>
								<PublicRoute exact path={PATHS.GET_OTP} component={GetOTP} />
								<PublicRoute exact path={PATHS.OTP} component={VerifyOTP} />
								<PublicRoute exact path={PATHS.AGENDA} component={Agenda} />
								<PublicRoute
									exact
									path={PATHS.SYS_REQ}
									component={SystemRequirement}
								/>
								<PublicRoute
									exact
									path={PATHS.TOKEN_LOGIN}
									component={TokenLogin}
								/>
								<PublicRoute
									exact
									path={PATHS.EVENT_REGISTRATION}
									component={Registration}
								/>
								<PublicRoute
									exact
									path={PATHS.RESET_PASSWORD}
									component={ResetPassword}
								/>
								<PublicRoute
									exact
									path={PATHS.SPECIAL_URL_TOKEN}
									component={SpecialToken}
								/>
								<PublicRoute exact path={PATHS.AT_LOGIN} component={ATLogin} />
								<PrivateRoute
									exact
									path={PATHS.EVENT_INFO}
									component={Information}
								/>
								<PrivateRoute exact path={PATHS.WATCH} component={Watch} />
								<PrivateRoute
									exact
									path={PATHS.EDIT_MY_SESSIONS}
									component={EditSession}
								/>
								<PublicRoute exact path={PATHS.NOT_FOUND} component={Home} />
							</Switch>
						</div>
					</Container>
				</div>
				<Footer />
				<ToastContainer limit={1} />
			</Router>
		</Provider>
	);
}

export default App;
