import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import { useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { withRouter, NavLink } from 'react-router-dom';
import { toggleSidebar, toggleVideo } from 'actions/system';
import { getCurrentEventSlug } from 'selectors/event';
import { isLoggedIn } from 'selectors/auth';
import { authLogoutRequest } from 'actions/auth';
import { firstEvent } from 'actions/event';
import { getContentBlock, clearContentData } from 'actions/event';
import { togglePosition } from 'selectors/system';
import PATHS from 'routes';
import { Helmet } from 'react-helmet';

const Navigation = (props) => {
	let {
		location,
		currentSlug,
		isLoggedIn,
		currentSidebarPosition,
		firstEvent,
	} = props;
	const { getContentBlock, clearContentData } = props;

	const initial = {
		toggle: currentSidebarPosition,
		displayToggle: location.pathname.includes('/watch-now/'),
		displayLogoutButton: false,
		slug: useRouteMatch('slug') || 'new-event',
		loginSlug: '',
		registration: '',
		displayRegister: false,
		loading: true,
	};
	const [state, setState] = useState(initial);

	const callbackSuccess = (data) => {
		if (data && data.data) {
			let register =
				(data.data.has_registration &&
					data.data.registration_slug &&
					state.slug) ||
				false;

			setState({
				...state,
				...data.data,
				displayRegister: register,
				loading: false,
			});
		} else {
			setState({
				...state,
				loading: false,
			});
		}
	};

	const callbackError = (data) => {
		setState({
			...state,
			...data.data,
		});
	};

	const eventSuccess = (data) => {
		const { slug, registration } = data[0] || '';
		if (slug)
			setState({
				...state,
				slug: slug,
				loginSlug: slug,
				registration: registration,
			});
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (state.slug) {
				getContentBlock(state.slug, callbackSuccess, callbackError);
			} else {
				setState(initial);
				clearContentData();
				firstEvent(eventSuccess);
			}
		}

		return () => {
			isMounted = false;
		};
	}, [state.slug]);

	const logoutSuccess = () => {
		toast.success('Logout Success.', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	const logoutError = () => {
		toast.error('Something went wrong.', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const logout = () => {
		if (!currentSlug) return '';
		const { authLogoutRequest } = props;
		authLogoutRequest(currentSlug, logoutSuccess, logoutError);
	};

	return (
		<>
			<div className="nav-cont">
				{(state.navigation_background_color ||
					state.navigation_text_color ||
					state.navigation_font_size ||
					state.navigation_active_color) && (
					<style>{`.navbar, #responsive-navbar-nav{\
					background: ${state.navigation_background_color} !important;\
				}
				#responsive-navbar-nav .nav-link{
					color: ${state.navigation_text_color} !important;
					font-size: ${state.navigation_font_size} !important;
					background-color: ${state.navigation_background_color};
				}
				#responsive-navbar-nav .nav-active{
					background: ${state.navigation_active_color} !important;
					color: ${state.navigation_active_text_color} !important;
				}
				`}</style>
				)}

				{state.tab_heading && (
					<Helmet>
						<title>{state.tab_heading}</title>
					</Helmet>
				)}
				<Container>
					{state.header_img && (
						<img src={state.header_img} className="banner-img header-img" />
					)}

					{
						<Navbar collapseOnSelect expand="lg">
							<Navbar.Toggle aria-controls="responsive-navbar-nav" />
							<Navbar.Collapse id="responsive-navbar-nav">
								<Nav className="mr-auto">
									<Nav.Link
										as={NavLink}
										to={PATHS.HOME}
										className={location.pathname === '/' ? 'nav-active' : ''}
									>
										Welcome
									</Nav.Link>

									{state.slug && isLoggedIn(currentSlug) && (
										<Nav.Link
											as={NavLink}
											to={`/${state.slug ? state.slug : currentSlug}/info`}
											className={
												location.pathname.includes('info') && 'nav-active'
											}
										>
											Winner Announcement
										</Nav.Link>
									)}

									<Nav.Link
										as={NavLink}
										to={PATHS.SPEAKERS}
										className={
											location.pathname === '/2021-sponsors' ? 'nav-active' : ''
										}
									>
										2021 Sponsors
									</Nav.Link>
									<Nav.Link
										as={NavLink}
										to={PATHS.JUDGES}
										className={
											location.pathname === '/judges' ? 'nav-active' : ''
										}
									>
										Judges
									</Nav.Link>
									<Nav.Link
										as={NavLink}
										to={PATHS.FINALISTS}
										className={
											location.pathname === '/2021-finalists'
												? 'nav-active'
												: ''
										}
									>
										2021 Finalists
									</Nav.Link>
								</Nav>
								<Nav>
									{isLoggedIn(currentSlug) && (
										<Nav.Link className="nav-links logout" onClick={logout}>
											Logout
										</Nav.Link>
									)}
								</Nav>
							</Navbar.Collapse>
						</Navbar>
					}
				</Container>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		currentSidebarPosition: togglePosition(state),
		currentSlug: getCurrentEventSlug(state),
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};
const dispatchProps = {
	toggleSidebar,
	toggleVideo,
	authLogoutRequest,
	firstEvent,
	getContentBlock,
	clearContentData,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(Navigation));
