import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getImageUrl } from 'helper/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookF,
	faLinkedin,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
	return (
		<footer>
			<div className="footer">
				<div className="container">
					<div className="footer-container">
						<img
							src={getImageUrl('/assets/images/footer.jpg')}
							className="w-100 h-100 bg-footer"
						/>
						<div className="logo-container w-100 h-100">
							<div className="footer-data">
								<a
									href="https://anziif.com"
									target="_blank"
									className="logo ml-4"
									rel="noreferrer"
								>
									<img src={getImageUrl('/assets/images/logo.png')} />
								</a>
								<div className="social-media">
									<div className="social">
										<a
											href="https://www.facebook.com/ANZIIF"
											target="_blank"
											rel="noreferrer"
										>
											<FontAwesomeIcon icon={faFacebookF} />
										</a>
										<a
											href="https://www.linkedin.com/school/anziif"
											target="_blank"
											rel="noreferrer"
										>
											<FontAwesomeIcon icon={faLinkedin} />
										</a>
										<a
											href="https://twitter.com/ANZIIF"
											target="_blank"
											rel="noreferrer"
										>
											<FontAwesomeIcon icon={faTwitter} />
										</a>
									</div>
									<div className="links">
										<a
											href="https://anziif.com/about/privacystatement"
											target="_blank"
											rel="noreferrer"
										>
											Privacy Statement
										</a>
										<a>|</a>
										<a
											href="https://anziif.com/about/terms-and-conditions"
											target="_blank"
											rel="noreferrer"
										>
											Terms and Conditions
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

const mapStateToProps = () => {
	return {};
};
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(withRouter(Footer));
